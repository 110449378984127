import React from 'react'
import Construction from '../components/construction/construction'

const Home = () => {
    return (
        <>
            <Construction/>
        </>
    )
}

export default Home