import React from 'react'
import './logo.scss'
import logo from '../../img/logo.png'

const Logo = () => {
    return (
        <>
            <img alt='logo de be digital' className='logo-navbar' src={logo}></img>
        </>
    )
}

export default Logo